.modal-content {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  background-color: #fff;

  .modal-header {
    position: relative;
    color: #fff;
    height: 58px;
    padding: 15px 20px;
    background-color: var(--secondary-color);
    border-radius: 4px 4px 0 0;

    h4 {
      font-size: 18px;
      margin-bottom: 0;
    }

    .close {
      height: 40px;
      font-size: 36px;
      font-weight: 500;
      transition: 0.2s ease-in;
      margin-left: 10px;
      cursor: pointer;
      user-select: none;

      &:hover {
        transform: rotate(360deg);
      }
    }
  }

  .modal-body {
    padding: 20px;

    .desc {
      font-size: 14px;
      color: #757575;
      margin-bottom: 24px;
    }

    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #fff;
      height: 36px;
      border-radius: 3px;
      padding: 6px 15px;
    }

    .cancel {
      color: #757575;
      background-color: #eee;

      &:hover {
        background-color: #f5f5f5;
      }

      &:active {
        background-color: #d5d5d5;
      }
    }

    .confirm {
      margin-left: 5px;
      background-color: var(--primary-color);

      &:hover {
        background-color: var(--primary-color-hover);
      }
    }
  }
}

@media screen and (min-width: 767px) {
  .dialog-track-limit {
    width: 600px;
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .modal-content {
    height: 100vh;

    .modal-header {
      height: 66px;
      border-radius: 0;
    }
  }

  .dialog-track-limit {
    margin: 0;

    :global {
      .modal-content {
        border: none;
      }
    }
  }
}
