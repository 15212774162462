.free-apply {
  padding: 100px 36px 120px;
  text-align: center;

  .title {
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;
    color: #424242;
  }

  .desc {
    font-size: 20px;
    color: var(--text-color-primary);
    line-height: 30px;
    margin: 10px 0 30px;
  }

  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #fff;
    height: 42px;
    background-color: var(--primary-color);
    border-radius: 8px;
    padding: 0 20px;
    text-decoration: none;

    a {
      color: #fff;
      text-decoration: none;
    }

    &:hover {
      background-color: var(--primary-color-hover);
    }
  }
}
