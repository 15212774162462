.tracking {
  width: 100%;
  max-width: 728px;
  // padding: 66px 0 0;
  margin: 0 auto;

  h1 {
    font-weight: 700;
    font-size: 40px;
    background-clip: text;
    color: var(--primary-color);
    margin: 22px 0 11px;
  }

  .queries-num {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-color-primary);
    margin: 10px 0 20px;
  }

  .track-wrapper {
    margin: 20px auto 0;

    .carrier-area {
      position: relative;
      width: 110px;
      height: 110px;
      font-size: 12px;
      color: var(--text-color-primary);
      background-color: #fff;
      border: 2px solid #bdbdbd;
      border-radius: 8px;

      .icon {
        font-size: 32px;
      }

      .auto-ident {
        color: #757575;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }

      &.selected {
        border-color: var(--primary-color);
      }

      .close-btn {
        width: 26px;
        color: #d8d8d8;
        font-size: 16px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        border-radius: 0 0 0 20px;
        padding-left: 6px;
        background-color: var(--primary-color);
        text-decoration: none;
      }

      &:hover {
        border: 2px solid var(--primary-color);
        color: var(--primary-color);

        .auto-ident {
          color: var(--primary-color);
        }
      }
    }

    .search-area {
      position: relative;
      width: 110px;
      height: 110px;
      color: #fff;
      background-color: var(--secondary-color);
      font-size: 18px;
      font-weight: 700;
      border-radius: 8px;

      .icon {
        font-size: 48px;
      }

      .badge {
        position: absolute;
        top: 1px;
        right: 1px;
        width: 56px;
        height: 32px;
        font-weight: 500;
        padding: 2px 0 0 26px;
        font-size: 14px;
        text-align: center;
        border-radius: 0 8px 0 0;
        color: var(--text-color-primary);
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAAgCAYAAABHA7voAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsSAAALEgHS3X78AAABEUlEQVRYw93TyQ3CMBBG4WdxZOkgVVAERbBc0hcV0ANFwX04YKQfKayx47HnZieH+SS/YGZroAc62pkrcAaOAcDMZsAGOADz0tslnFPQk5mtgG3Ezkpvl2AuYejWzDruz3ZdesOxE959bKHP8OkH6XMPLEovnBwo0Cr7/Boo0Kr6/Bko0Cr6/BsYke77HAUUqNs+kwAF6q7PpECBuukzCzAiXfSZDSjQon1mBwq0SJ+TAQU6aZ+TAyNysj6LAAWavc+iQIFm69MFUKDJ+3QFjMhHnztg2RxQoEn6dAsU6Kg+3QMF+lef1QAj8uc+qwIK9Os+qwQK9GOfVQMF+rLPJoAROdhnM0CBPvXZHFCgHdDfAMk0ed4hd3SYAAAAAElFTkSuQmCC)
          no-repeat;
      }
    }
  }

  .track-history {
    margin: 10px auto;
    padding: 0;
    list-style: none;

    li {
      position: relative;
    }

    .text-label {
      display: inline-block;
      color: #424242;
      font-size: 14px;
      line-height: 22px;
    }

    a {
      display: inline-flex;
      align-items: center;
      color: var(--text-color-primary);
      margin-right: 5px;
      background-color: #eee;
      border-radius: 10px;
      font-size: 12px;
      padding: 2px 8px;
      cursor: pointer;
      word-break: break-all;
      text-decoration: none;
      max-width: 260px;

      i {
        width: 13px;
        margin-left: 6px;
        font-size: 13px;
        color: #9e9e9e;

        &:hover {
          color: var(--text-color-primary);
        }
      }

      &:hover {
        background-color: #e0e0e0;
      }

      &.more {
        position: relative;
        color: #424242;
        user-select: none;
        margin-right: 0;

        .caret {
          display: inline-block;
          content: '';
          width: 0;
          height: 0;
          margin-left: 2px;
          vertical-align: middle;
          border-top: 0;
          border-bottom: 4px dashed;
          border-right: 4px solid transparent;
          border-left: 4px solid transparent;
        }
      }
    }

    .dropdown-menu {
      position: absolute;
      right: 0;
      visibility: hidden;
      display: flex;
      flex-direction: column;
      width: max-content;
      margin: 5px 0 6px;
      padding: 10px 10px 5px;
      background-color: #fff;
      border: 1px solid #e0e0e0;
      border-radius: 3px;

      &-item {
        margin-bottom: 5px;
      }

      &.show {
        visibility: visible;
      }

      a {
        margin-right: 0;
      }
    }
  }

  .gad-container {
    width: 0;
    height: 0;
    margin: 20px auto 30px;
    overflow: hidden;
    border: 1px solid #757575;

    > div {
      margin: -1px !important;
    }
  }
}

@media (min-width: 320px) and (min-height: 100px) {
  .tracking {
    .gad-container {
      width: 320px;
      height: 50px;
    }
  }
}

@media (min-width: 468px) {
  .tracking {
    .gad-container {
      width: 468px;
      height: 60px;
    }
  }
}

@media (min-width: 768px) {
  .tracking {
    .gad-container {
      max-width: 728px;
      width: 100%;
      height: 90px;
    }
  }
}

@media (max-width: 991px) {
  .tracking {
    padding: 0px 15px 0;
  }
}
