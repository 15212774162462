.multi-scenario {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 30px;
  background-color: #fff;

  .title {
    font-weight: 700;
    font-size: 40px;
    color: var(--text-color-primary);
    letter-spacing: 0;
    line-height: 48px;
    text-align: center;
    margin: 30px 0 0;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    padding-bottom: 30px;

    li {
      flex: 0 0 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 60px;

      .info-logo {
        width: 72px;
        height: 72px;
      }

      .info-title {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 40px;
        line-height: 48px;
        color: var(--text-color-primary);
        margin: 6px 0 10px;

        > img {
          width: auto;
          height: 25px;
          margin-left: 10px;
        }
      }

      .info-desc {
        font-size: 20px;
        margin-bottom: 0;
      }

      .info-link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: #fff;
        height: 46px;
        background-color: var(--primary-color);
        border-radius: 8px;
        padding: 0 20px;
        margin-top: 20px;
        text-decoration: none;

        &:hover {
          background-color: var(--primary-color-hover);
        }
      }

      .badge {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        padding: 4px 10px;
        margin-left: 10px;
        border-radius: 16px;
        background-color: var(--secondary-color);
      }

      .qrcode-wrapper {
        display: flex;
        margin-top: 30px;
      }

      .qr-code {
        width: 180px;
        height: 208px;
        border: 1px solid #e0e0e0;
      }

      .app-download {
        display: flex;
        gap: 20px;
        margin-top: 20px;

        a {
          background-color: none;
          text-decoration: none;
        }

        img {
          width: 122px;
          height: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .multi-scenario {
    padding: 0 15px;
  }
}

@media screen and (max-width: 767px) {
  .multi-scenario {
    ul {
      flex-direction: column;
      justify-content: flex-start;

      li > div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      p {
        text-align: center;
      }
    }
  }
}
