.batch-track {
  max-width: 728px;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;

  &.track-brand {
    flex-direction: column;

    .track-wrapper {
      width: 100%;
      gap: 0;

      .search-area {
        display: flex !important;
        width: 100%;
        height: 44px;
        margin-top: 20px;

        .icon {
          font-size: 20px;
          margin-right: 4px;
        }
      }
    }

    .textarea-wrapper {
      .textarea {
        min-height: 128px;
      }

      .operate-tool {
        button {
          border-radius: 2px;
        }
      }
    }
  }
}

// 特殊情况底部按钮
.search-area-bottom {
  position: relative;
  max-width: 540px;
  width: 100%;
  height: 40px;
  font-size: 18px;
  margin: 20px auto 20px;
  border-radius: 8px;
  border-color: #bdbdbd;
  .badge {
    position: absolute;
    border-radius: 0 8px 0 0;
    top: 1px;
    right: 1px;
    width: 56px;
    height: 32px;
    padding-top: 3px;
    padding-left: 26px;
    font-size: 14px;
    color: #212121;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAAgCAYAAABHA7voAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsSAAALEgHS3X78AAABEUlEQVRYw93TyQ3CMBBG4WdxZOkgVVAERbBc0hcV0ANFwX04YKQfKayx47HnZieH+SS/YGZroAc62pkrcAaOAcDMZsAGOADz0tslnFPQk5mtgG3Ezkpvl2AuYejWzDruz3ZdesOxE959bKHP8OkH6XMPLEovnBwo0Cr7/Boo0Kr6/Bko0Cr6/BsYke77HAUUqNs+kwAF6q7PpECBuukzCzAiXfSZDSjQon1mBwq0SJ+TAQU6aZ+TAyNysj6LAAWavc+iQIFm69MFUKDJ+3QFjMhHnztg2RxQoEn6dAsU6Kg+3QMF+lef1QAj8uc+qwIK9Os+qwQK9GOfVQMF+rLPJoAROdhnM0CBPvXZHFCgHdDfAMk0ed4hd3SYAAAAAElFTkSuQmCC)
      no-repeat;
  }
}

.textarea-wrapper {
  width: 100%;
  height: 230px;
  background-color: #fff;
  border: 2px solid #bdbdbd;
  border-radius: 8px;
  padding-bottom: 36px;
  overflow: hidden;

  &.focus {
    border-color: var(--secondary-color);
  }

  .main-box {
    position: relative;
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      background: #eee;
    }

    .textarea {
      width: 100%;
      height: auto;
      min-height: 190px;
      line-height: 28px;
      font-size: 14px;
      color: #000;
      position: absolute;
      z-index: 0;
      padding: 0 32px;
      resize: none;
      border: none;
      outline: none;
      border-radius: 8px 8px 0 0;
      background-color: transparent;

      &::placeholder {
        color: #9e9e9e;
      }

      &.adjust {
        padding: 0 32px 0 42px;
      }
    }

    .number-item {
      display: flex;
      color: #757575;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;

      &:nth-child(odd) {
        background-color: rgba(238, 238, 238, 0.3);
      }

      .num-box {
        min-width: 20px;
        width: 32px;
        font-family:
          Courier New,
          Andale Mono,
          Monaco;
        font-size: 14px;
        color: #757575;
        padding: 0 3px 0 5px;
        text-align: right;
        white-space: nowrap;
      }

      .main-text {
        flex: 1;
        padding: 0 4px;
        color: #000;
        word-break: break-all;
        visibility: hidden;
        background-color: rgba(238, 238, 238, 0.3);
      }

      .icon-box {
        width: 28px;
        height: 28px;
        z-index: 1;
        cursor: pointer;

        .close {
          color: #bfbfbf;
          font-size: 14px;
        }

        &:hover {
          .close {
            color: var(--text-color-primary);
          }
        }
      }
    }
  }

  .operate-tool {
    height: 36px;
    gap: 15px;
    transform: translateY(42px);
    width: 100%;
    padding: 4px;
    padding-top: 0;
    transition: 0.3s;

    &.in {
      transform: translateY(0);
    }

    button {
      width: 100%;
      min-width: 32px;
      height: 32px;
      font-size: 12px;
      border-radius: 4px;
      color: #fff;
      background-color: #616161;
      border-color: #616161;

      i {
        margin: -1px 3px 0;
      }

      &:hover {
        background-color: #757575;
        border-color: #757575;
      }
    }
  }
}

.track-wrapper {
  gap: 10px;

  .carrier-area {
    position: relative;
    width: 110px;
    height: 110px;
    font-size: 12px;
    color: var(--text-color-primary);
    background-color: #fff;
    border: 2px solid #bdbdbd;
    border-radius: 8px;

    .icon {
      font-size: 32px;
    }

    .auto-ident {
      color: #757575;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }

    &.selected {
      border-color: var(--primary-color);
    }

    .close-btn {
      width: 26px;
      color: #d8d8d8;
      font-size: 16px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
      border-radius: 0 0 0 20px;
      padding-left: 6px;
      background-color: var(--primary-color);
      text-decoration: none;
    }

    &:hover {
      border: 2px solid var(--primary-color);
      color: var(--primary-color);

      .auto-ident {
        color: var(--primary-color);
      }
    }
  }

  .search-area {
    position: relative;
    width: 110px;
    height: 110px;
    color: #fff;
    background-color: var(--secondary-color);
    font-size: 18px;
    font-weight: 700;
    border-radius: 8px;

    &:hover {
      background-color: var(--secondary-color-hover);
    }

    .icon {
      font-size: 48px;
    }

    .badge {
      position: absolute;
      top: 1px;
      right: 1px;
      width: 56px;
      height: 32px;
      font-weight: 500;
      padding: 2px 0 0 26px;
      font-size: 14px;
      text-align: center;
      border-radius: 0 8px 0 0;
      color: var(--text-color-primary);
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAAgCAYAAABHA7voAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAAsSAAALEgHS3X78AAABEUlEQVRYw93TyQ3CMBBG4WdxZOkgVVAERbBc0hcV0ANFwX04YKQfKayx47HnZieH+SS/YGZroAc62pkrcAaOAcDMZsAGOADz0tslnFPQk5mtgG3Ezkpvl2AuYejWzDruz3ZdesOxE959bKHP8OkH6XMPLEovnBwo0Cr7/Boo0Kr6/Bko0Cr6/BsYke77HAUUqNs+kwAF6q7PpECBuukzCzAiXfSZDSjQon1mBwq0SJ+TAQU6aZ+TAyNysj6LAAWavc+iQIFm69MFUKDJ+3QFjMhHnztg2RxQoEn6dAsU6Kg+3QMF+lef1QAj8uc+qwIK9Os+qwQK9GOfVQMF+rLPJoAROdhnM0CBPvXZHFCgHdDfAMk0ed4hd3SYAAAAAElFTkSuQmCC)
        no-repeat;
    }
  }
}

@media (max-width: 767px) {
  .batch-track {
    flex-direction: column;
  }

  .textarea-wrapper {
    .operate-tool {
      &.mobile {
        display: flex !important;

        button {
          min-width: 32px;
          max-width: max-content;
          font-size: 14px;
          border-radius: 2px;
        }

        i {
          margin: 0;
        }

        .truck {
          font-size: 18px;
        }

        .carrier-btn {
          max-width: 200px;
          height: 32px;
          color: #fff;
          padding-right: 10px;
          border-radius: 2px;
          border: 1px solid var(--primary-color);
          background-color: var(--primary-color);

          img {
            width: 30px;
            height: 30px;
          }

          .name {
            padding: 0 8px 0 10px;
            font-size: 14px;
          }

          &:hover {
            background-color: #1650b1;
          }
        }
      }
    }
  }

  .track-wrapper {
    width: 100%;
    gap: 0;

    .search-area {
      &.mobile {
        display: flex !important;
        width: 100%;
        height: 44px;
        margin-top: 8px;

        .icon {
          font-size: 20px;
          margin-right: 4px;
        }
      }
    }
  }
}
