.dialog-carrier {
  width: 728px;
  max-width: 100%;
}

.carrier-content {
  display: flex;
  flex-direction: column;
  height: 600px;
  position: relative;
  background-color: #fff;
  border: none;

  .modal-header {
    position: relative;
    color: #fff;
    height: 58px;
    padding: 15px 20px;
    background-color: var(--primary-color);
    border-radius: 8px 8px 0 0;

    h4 {
      font-size: 18px;
      // width: calc(100% - 350px);
      margin-bottom: 0;
    }

    .input-search {
      position: absolute;
      right: 20px;
      transition: 0.3s ease;
      width: 330px;
      z-index: 100;

      &.focus {
        width: calc(100% - 38px);
      }

      .search-icon {
        color: #9e9e9e;
        position: absolute;
        top: 50%;
        z-index: 1;
        width: 36px;
        left: 8px;
        font-size: 16px;
        color: #9e9e9e;
        text-align: center;
        pointer-events: none;
        transform: translateY(-50%);
      }

      .input-control {
        width: 100%;
        min-width: 300px;
        height: 36px;
        font-size: 14px;
        color: #757575;
        border-radius: 200px;
        padding: 6px 36px 6px 43.5px;
        outline: none;
        box-shadow: none;
        margin-right: 30px;
        border: 1px solid #e0e0e0;
      }

      .input-search-close {
        position: absolute;
        top: 50%;
        right: 36px;
        z-index: 1;
        width: 36px;
        height: 22px;
        transform: translateY(-50%);
        color: #bfbfbf;

        &:hover {
          color: var(--text-color-primary);
        }
      }

      .close {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 36px;
        font-weight: 500;
        margin-left: 10px;
        cursor: pointer;
        user-select: none;

        span {
          display: inline-block;
          height: 36px;
          line-height: 36px;
          transition: 0.2s ease-in;
        }

        &:hover {
          span {
            transform: rotate(360deg);
          }
        }
      }
    }
  }

  .modal-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 8px;

    .auto-detect {
      font-size: 14px;
      color: var(--text-color-primary);
      position: relative;
      display: flex;
      align-items: center;
      padding: 12px 0;
      margin: 0 20px;
      border-bottom: 1px solid #e0e0e0;

      .current-detect {
        color: #616161;
        margin-right: 5px;
      }

      .current-info {
        img {
          width: 22px;
          height: 22px;
        }

        span {
          font-size: 14px;
          color: var(--text-color-primary);
          margin-left: 5px;
        }
      }

      .datect-btn {
        &:hover .datect-desc {
          color: var(--primary-color);
        }

        &.selected span {
          color: var(--primary-color);
          background-color: transparent;
          text-decoration: underline;
        }
      }

      .datect-logo {
        position: absolute;
        width: 22px;
        height: 22px;
        color: #616161;
        text-align: center;
        background-color: #eee;
        border-radius: 2px;
      }

      .datect-desc {
        display: block;
        margin-left: 27px;
        overflow: hidden;
        color: var(--text-color-primary);
      }
    }
  }

  .scrollable-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .wrapper {
      padding: 15px 20px 0;

      h6 {
        font-size: 14px;
        font-weight: 600;
        color: var(--text-color-primary);
        margin-bottom: 0;
      }

      .list-group {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
      }

      .list-group-item {
        padding: 10px 0;
        border: 1px solid transparent;

        a {
          display: flex;
          font-size: 12px;
          color: var(--text-color-primary);
          cursor: pointer;

          &:hover {
            color: var(--primary-color);
            text-decoration: underline;
          }
        }

        .carrier-logo {
          width: 34px;
          height: 34px;
          outline: 1px solid #e0e0e0;
          border-radius: 2px;
        }
      }
    }
  }

  .empty-data {
    padding: 10px 20px;

    h4 {
      font-size: 18px;
      color: #424242;
      text-align: center;
      padding: 11px 0;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 767px) {
  .dialog-carrier {
    width: 100%;
    margin: 0;

    :global {
      .modal-content {
        border: none;
      }
    }
  }

  .carrier-content {
    height: 100vh;
    border-radius: 0;

    .modal-header {
      height: 66px;
      border-radius: 0;

      .input-search {
        width: 155px;

        .input-control {
          min-width: 125px;
        }
      }
    }

    .modal-body {
      border-radius: none;
    }
  }
}
