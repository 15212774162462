.feature {
  position: relative;
  color: #fff;
  padding-top: 60px;
  background-image: linear-gradient(0deg, #385eff 0%, #0027a6 100%);

  .intro {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 15px;

    .title {
      font-size: 40px;
      font-weight: 700;
      text-align: center;
      color: transparent;
      background-image: linear-gradient(160deg, #ff9e25 -18%, #fff 37%);
      background-clip: text;
      margin-top: 0;
      margin-bottom: 6px;
    }

    .desc {
      font-size: 16px;
      color: #fff;
      line-height: 25px;
      text-align: center;
      margin-bottom: 0 !important;
    }

    .feature-item {
      padding: 0 15px;

      .lightspot {
        margin-top: 100px;
        position: relative;

        &:hover .info {
          background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.32));
        }
      }

      .info {
        border-radius: 18px 100px 18px 18px;
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.16));
        transition: all 0.3s;
        height: 220px;
        padding: 70px 30px 40px;

        h3 {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 10px;
        }

        p {
          margin-bottom: 0;
        }
      }

      .mask-layer {
        position: absolute;
        top: -70px;
        display: inline-block;
        width: 260px !important;
        height: 160px !important;
        overflow: hidden;
        background-repeat: no-repeat;
      }

      :global {
        .wp-service-01 {
          background-position: 0 0;
        }

        .wp-service-02 {
          background-position: -260px 0;
        }

        .wp-service-03 {
          background-position: 0 -160px;
        }

        .wp-service-04 {
          background-position: -260px -160px;
        }

        .wp-service-05 {
          background-position: 0 -320px;
        }

        .wp-service-06 {
          background-position: -260px -320px;
        }
      }
    }
  }

  .supported {
    position: relative;
    z-index: 99;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 90px;
    padding: 30px 15px 0;

    .title {
      font-size: 40px;
      font-weight: 700;
      text-align: center;
      color: transparent;
      background-image: linear-gradient(160deg, #ff9e25 -18%, #fff 37%);
      background-clip: text;
      margin-top: 0;
      margin-bottom: 6px;
    }

    .desc {
      font-size: 16px;
      color: #fff;
      line-height: 25px;
      text-align: center;
      margin-bottom: 30px;
    }

    .list {
      display: flex;
      align-items: center;

      > div {
        padding: 0 15px;
      }

      .col {
        display: none;
        height: 260px;
      }

      .supported-item {
        height: 160px;
        overflow: hidden;
        margin: 0 5px;
        border-radius: 16px;
        box-shadow:
          0 1px 4px 0 rgba(0, 23, 102, 0.12),
          0 4px 12px 0 rgba(0, 23, 102, 0.24);
        background-image: linear-gradient(to bottom, #fff, #f5f5f5);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        h3 {
          font-size: 24px;
          font-weight: 700;
          color: #424242;
          margin: 0;
        }

        p {
          width: 100%;
          color: #616161;
          font-size: 16px;
          line-height: 25px;
          margin: 0;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .supported-item.main {
        height: 260px;
        border-radius: 32px;
        background-image: linear-gradient(to bottom, #fff, #fff3e6);

        h3 {
          font-size: 60px;
          color: var(--secondary-color);
        }

        p {
          font-size: 24px;
          line-height: 36px;
        }
      }
    }
  }

  .brand-wrapper {
    overflow: hidden;
    position: relative;
    height: 100px;
    margin-top: 40px;
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.1);

    @keyframes move_brand {
      0% {
        transform: translateX(-100px);
      }

      100% {
        transform: translateX(-1900px);
      }
    }

    .brand-list {
      position: absolute;
      left: 0;
      top: 0;
      width: 200%;
      margin: 0;
      padding: 0;
      animation: 15s move_brand infinite linear;

      &:hover {
        animation-play-state: paused;
      }

      > li {
        float: left;
        width: 100px;
        height: 100px;
        list-style: none;
        opacity: 0.1;
        transition: opacity 0.3s;
        background-color: #ffffff;

        a {
          cursor: pointer;
        }

        img {
          max-width: 100%;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .advantage-bg {
    position: absolute;
    bottom: 0;
    z-index: 1;
    height: 560px;
    width: 100%;
    background-position: center;
  }

  @media screen and (max-width: 1199px) {
    .list {
      :global {
        .row > div {
          margin-bottom: 40px;
        }
      }
    }
  }
}
