.logistics_recommend {
  color: var(--text-color-primary);
  background: #f6f6f6;

  .panel-coop {
    .title {
      font-size: 40px;
      font-weight: 700;
      color: var(--text-color-primary);
      text-align: center;
      text-transform: uppercase;
      margin: 22px 0 5px;
    }

    .desc {
      color: var(--text-color-primary);
      font-size: 16px;
      text-align: center;
      margin-bottom: 30px;
    }

    [data-view-carrier] {
      max-width: 360px;
    }

    > svg {
      display: inherit;
    }

    .seo-container {
      position: absolute;
      visibility: hidden;
      z-index: -1000;
    }

    .coop-container {
      max-width: 1440px;
      padding: 0 30px;
      margin: 0 auto 30px;

      .imgCover {
        :global {
          img {
            object-fit: cover;
          }
        }
      }

      .coop-carrier-item {
        position: relative;
        height: 120px;
        padding: 0 15px;
        margin-bottom: 30px;

        a {
          position: relative;
          display: block;
          height: 100%;
          overflow: hidden;
          color: #757575;
          background: #fff;
          transform: scale(1);
          transition: 0.3s;
          border-radius: 12px;
          border: 1px solid #e0e0e0;

          .e-logo {
            position: relative;
            width: auto;
            height: 100%;
            opacity: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: scale(1, 1);
            transition: 0.3s ease-in-out;

            img {
              width: 116px;
              height: 116px;
            }
          }

          .mask {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-position: center center;
            background-repeat: no-repeat;
            z-index: 1;
            opacity: 0;
            transform: scale(1.5, 1.5);
            transition: 0.4s ease-in-out;
            display: flex;
            box-align: center;
            align-items: center;
            justify-content: center;
            box-pack: center;

            img {
              height: 100%;
            }
          }

          .action {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            text-align: center;
            color: #212121;
            z-index: 3;

            .name {
              position: relative;
              display: block;
              padding-top: 25px;
              font-size: 20px;
              font-weight: 600;
              opacity: 0;
              transform: translateY(-20px);
              transition: 0.4s ease-in-out;
            }

            .btns {
              display: inline-block;
              position: absolute;
              left: 50%;
              bottom: 28px;
              margin-left: -40px;
              width: 80px;
              line-height: 24px;
              border-radius: 4px;
              opacity: 0;
              padding: 1px 5px;
              font-size: 12px;
              color: #fff;
              background-color: var(--secondary-color);
              border-color: var(--secondary-color);
              transform: translateY(20px);
              transition: 0.4s ease-in-out;
              user-select: none;
              -webkit-tap-highlight-color: transparent;
            }
          }

          &:hover {
            .e-logo {
              opacity: 0.15;
              color: #fff;
              transform: scale(1.5, 1.5);
            }

            .mask {
              opacity: 1;
              transform: scale(1, 1);
            }

            .action .name {
              opacity: 1;
              transform: translateY(0px);
            }

            .action .btns {
              opacity: 1;
              transform: translateY(0px);
            }
          }
        }
      }

      .view-all {
        display: block;
        font-size: 14px;
        max-width: 360px;
        line-height: 34px;
        color: var(--primary-color);
        margin: 0 auto;
        border-radius: 8px;
        text-align: center;
      }
    }
  }
}
